import React, { useState, useEffect } from "react";

import Footer from "./Footer/Footer";
import { ContactEmergency } from "@mui/icons-material";
import { Container, Divider, Typography, styled } from "@mui/material";
import css from "./Addmission.module.css";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import { Link, NavLink } from "react-router-dom";

const information = [
  "Student's name and the names of parents or guardians.",
  "Email address, telephone or mobile number, postal address, and similar contact details.",
  "Student's age, date of birth, and details about family members and siblings.",
  "Contact information of relatives or friends who interact with the school.",
  "Payment details, including those made via cheque, net banking, credit card, or debit card.",
  "Information about medical conditions or ailments.",
  "Preferences for future communication.",
];

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 600,
  padding: "15px 0px",
  fontFamily: "Merriweather, serif",
}));

const Content = styled(Typography)(({ theme }) => ({
  fontFamily: "Source Sans Pro, serif",
  fontSize: "16px",
}));

const PrivacyPolicy = () => {
  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>PRIVACY POLICY</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / PRIVACY POLICY
          </h5>
        </div>
      </div>
      <Container maxWidth="xl">
        {/* <Typography
          sx={{
            fontWeight: 600,
            fontSize: "25px",
            padding: "15px 0px",
            fontFamily: "Merriweather, serif",
            textAlign: "center",
          }}
        >
          PRIVACY POLICY
        </Typography>
        <Divider
          sx={{ mb: 2, color: "gray", background: "gray", height: "2px" }}
        /> */}

        <Content mt={2}>
          Thank you for visiting the Excellent School website. At Excellent
          School, we are dedicated to earning and preserving your trust by
          safeguarding the personal information we gather about you. This
          statement explains our policy on how we handle and utilize personal
          data that you provide to us or that we collect.{" "}
        </Content>

        <SubTitle>INFORMATION WE COLLECT</SubTitle>
        <Content>
          Excellent School gathers necessary information directly from you
          during the pre-admissions process, when you express interest in
          enrolling your child at Excellent School, or through other direct
          interactions. Typically, we collect information via application forms
          you complete, discussions, meetings, interviews, and phone calls
          during and after a student's admission to the school. Any data that is
          not mandatory will be clearly marked as voluntary or optional for
          disclosure.{" "}
        </Content>

        <SubTitle>PERSONAL INFORMATION</SubTitle>
        <Content>
          Excellent School may collect the following information directly during
          the admission process:
        </Content>
        <Typography component={"ul"}>
          {information.map((row, index) => (
            <Content sx={{ padding: "8px 0px" }} component={"li"} key={index}>
              {row}
            </Content>
          ))}
        </Typography>

        <SubTitle>HOW WE USE THIS PERSONAL INFORMATION</SubTitle>
        <Content>
          At Excellent School, we share information with students and parents
          through various digital and electronic channels, including email, SMS,
          phone calls, and postal mail. We collect this information to keep you
          informed about the student’s progress, development, and other
          school-related matters. Additionally, we provide updates on events,
          celebrations, competitions, sports, and other activities organized by
          the school.
        </Content>

        <Content sx={{ pt: 1 }}>
          <b> Note:</b> If you wish to unsubscribe from receiving future emails,
          simply reply to the email with your request, and we will promptly
          remove your information from our database.
        </Content>

        <SubTitle>INFORMATION SHARING AND SECURITY</SubTitle>
        <Content>
          Excellent School is dedicated to safeguarding personal information and
          has implemented appropriate technical, physical, and administrative
          measures to protect it from loss, misuse, or unauthorized alteration.
        </Content>

        <SubTitle>INFORMATION TRANSFER</SubTitle>
        <Content>
          At Excellent School, access to personal information is restricted to
          authorized personnel and is used solely for academic-related purposes.
          Personal information may be shared with government departments,
          medical practitioners, service providers to the school (such as
          visiting teachers and sports coaches), on a need-to-know basis.
          Additionally, we may disclose information to government or statutory
          authorities if required by law or directed by a court order.
        </Content>

        <SubTitle>POLICY CHANGES</SubTitle>
        <Content>
          Any information collected by Excellent School under the current
          website privacy policy will continue to be governed by the terms of
          this policy. Excellent School reserves the right to modify, update,
          add to, or remove any provisions outlined in this policy. If changes
          are made to our Privacy Policy, they will be posted on this page, and
          the modification date at the top of the page will be updated
          accordingly. Changes to the policy will take effect 30 days after
          being posted and will apply only to information collected from that
          date onward.
        </Content>

        <SubTitle>COOKIES</SubTitle>
        <Content>
          Cookies are unique pieces of computer data that many websites,
          including ours, transfer to your device during your first visit. These
          cookies are stored on your hard drive and can be accessed later by the
          website to track your previous usage. Cookies may also be used to
          provide customized and personalized services by tracking the pages you
          have visited.
        </Content>
        <br />
        <Content>
          At Excellent School, we do not store any personal information in
          cookies, web beacons, or similar technologies on our systems.
          Therefore, you are not required to share any personal information
          through this website unless you choose to do so. Please note that we
          cannot guarantee the confidentiality of emails and recommend avoiding
          sending sensitive information via email.
        </Content>
        <br />
        <Content>
          If you believe that the personal information held by Excellent School
          is incorrect or needs updating, please contact us at{" "}
          <Typography
            component={"a"}
            href="mailto:excellentschool2008@gmail.com"
            sx={{
              color: "purple",
              fontFamily: "Source Sans Pro, serif",
              fontSize: "16px",
            }}
          >
            excellentschool2008@gmail.com
          </Typography>
          , and we will assist you.{" "}
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
