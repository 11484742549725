import React, { useEffect, useState } from "react";
import { urls } from "../../services/urlConstant";
import { del, get } from "../../services/apis";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Delete, Edit } from "@mui/icons-material";
import { Card, Dialog, Grid, Tooltip, Typography } from "@mui/material";
import RemoveRedEyeRounded from "@mui/icons-material/RemoveRedEyeRounded";
import DeleteModal from "../Layout/Academic/DeleteModal";
import dayjs from "dayjs";

import { DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import VacationAdmissionForm from "./VacationAdmissionForm";

export default function VacationAdmission() {
  const [list, setList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [dataToShow, setDataShow] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getList = async () => {
      try {
        const { data } = await get(urls.vaccationForm.list);

        setList(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getList();
  }, []);

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.vaccationForm.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDataShow({});
  };

  const handleView = async (data) => {
    setDataShow(data);
    setOpen(true);
  };

  return (
    <>
      {" "}
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Vacation Admission:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="academicYear-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">
                      Name of Pupil
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">Class</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">Date</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="academicYear-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {list.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.nameOfPupil}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.class}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {dayjs(row.date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      <div className="edit-delete">
                        <Tooltip title="View" disableInteractive>
                          <Button onClick={() => handleView(row)}>
                            <RemoveRedEyeRounded />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete" disableInteractive>
                          <Button onClick={() => setDeleteModal(row._id, row)}>
                            <Delete />
                          </Button>
                        </Tooltip>
                      </div>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDeleteone}
                        id={row._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!list.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ "& .MuiDialog-paper": { width: "800px", maxWidth: "90%" } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: "18px" }}
          id="customized-dialog-title"
        >
          View Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <Divider sx={{ height: "1px", backgroundColor: "gray" }} />
        <DialogContent
          sx={{
            padding: "10px",
          }}
        >
          <VacationAdmissionForm
            handleClose={handleClose}
            dataToShow={dataToShow}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
