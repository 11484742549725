import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";

import DeleteModal from "../Layout/Academic/DeleteModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Switch from "@mui/material/Switch";
import SectionSubHeader from "../Layout/SectionSubHeader";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",

  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
const label = { inputProps: { "aria-label": "Switch demo" } };

const AluminiFeedback = () => {
  const [activeButton, setActiveButton] = useState(1);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [showMessage, setShowMessage] = useState(null);
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const handleClose = () => setOpen(false);

  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log(list, "==list");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleOpen = (data) => {
    setShowMessage(data);
    setOpen(true);
  };

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
    if (buttonIndex === 1) {
      setFilteredList([...list]);
    } else if (buttonIndex === 2) {
      setFilteredList([...list.filter((f) => f.approved === true)]);
    } else {
      setFilteredList([...list.filter((f) => f.approved == false)]);
    }
  };

  useEffect(() => {
    getlist();
  }, []);

  const getlist = async () => {
    try {
      const res = await get(urls.aluminiFeedback.list);
      let data = res.data.data;
      setList(data);
      if (activeButton === 1) {
        setFilteredList([...data]);
      } else if (activeButton === 2) {
        setFilteredList([...data.filter((f) => f.approved === true)]);
      } else {
        setFilteredList([...data.filter((f) => f.approved == false)]);
      }
      console.log(res, "list");
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (id) => {
    try {
      const res = await put(
        `${urls.aluminiFeedback.updateFeedbackApproval}${id}`,
        "",
        {}
      );

      getlist();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.aluminiFeedback.deleteFeedback}`, id, {});
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
        getlist();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadingExcel(true);
      const res = await get(urls.aluminiFeedback.downloadFeedback, {
        responseType: "blob",
      });

      if (res.status === 200) {
        const uri = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "Alumini_Feedback_List.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
    setDownloadingExcel(false);
  };

  return (
    <Box sx={{ margin: "10px" }}>
      <SectionSubHeader title="Alumni Feedback" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <LoadingButton
          variant="contained"
          size="small"
          onClick={handleDownload}
          loading={downloadingExcel}
        >
          Download Feedback List
        </LoadingButton>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            variant="outlined"
            onClick={() => {
              handleButtonClick(1);
            }}
            style={{
              backgroundColor: activeButton === 1 ? "#1b3779" : "",
              color: activeButton === 1 ? "white" : "black",
              textTransform: "capitalize",
            }}
          >
            All:{list.length}
          </Button>
          <Button
            onClick={() => {
              handleButtonClick(2);
            }}
            style={{
              backgroundColor: activeButton === 2 ? "#1b3779" : "",
              color: activeButton === 2 ? "white" : "black",
              textTransform: "capitalize",
            }}
          >
            Approved: {list.filter((item) => item.approved === true).length}
          </Button>
          <Button
            onClick={() => {
              handleButtonClick(3);
            }}
            style={{
              backgroundColor: activeButton === 3 ? "#1b3779" : "",
              color: activeButton === 3 ? "white" : "black",
              textTransform: "capitalize",
            }}
          >
            Pending:{list.filter((item) => item.approved === false).length}
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Contact Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Current Position
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Feedback
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, i) => {
                  return (
                    <TableRow key={data._id}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">{data.name}</TableCell>
                      <TableCell align="center">{data.contactNumber}</TableCell>
                      <TableCell align="center">
                        {data.currentPosition}
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title="View">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleOpen(data)}
                          >
                            <VisibilityIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={`${
                            data.approved === true ? "Approved" : "Pending"
                          }`}
                        >
                          <Switch
                            onClick={() => handleUpdate(data._id)}
                            fontSize="small"
                            {...label}
                            defaultChecked={data.approved ? true : false}
                          />
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => setDeleteModal(data._id)}
                          >
                            <DeleteIcon color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>

                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {filteredList.length === 0 && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              textAlign="center"
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Alumni Feedback:
            </Typography>
            {showMessage ? (
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {showMessage.feedback}
              </Typography>
            ) : null}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AluminiFeedback;
