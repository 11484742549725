import React, { useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Paper,
  styled,
  Button,
  Stack,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Phone, WhatsApp } from "@mui/icons-material";
import { post } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import { useNavigate } from "react-router-dom";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import { ConsoleLevel } from "@zegocloud/zego-uikit-prebuilt";
const ListItem = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  marginBottom: "5px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    width: "100%",
  },
}));

const VacationAdmissionForm = ({ dataToShow, handleClose = () => {} }) => {
  const [previewUrl, setPreviewUrl] = useState(
    dataToShow ? dataToShow?.studentPhoto : null
  );
  const [signaturePreview, setSignaturePreview] = useState(
    dataToShow ? dataToShow?.parentSignature : null
  );
  const navigate = useNavigate();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [addForm, setAddForm] = useState({
    year: "2025",
    class: dataToShow?.class || "",
    school: dataToShow?.school || "",
    residency: dataToShow?.residency || "",
    board: dataToShow?.board || "",
    gender: dataToShow?.gender || "",
    nameOfPupil: dataToShow?.nameOfPupil || "",
    dateOfBirth: dataToShow?.dateOfBirth || "",
    motherTongue: dataToShow?.motherTongue || "",
    nationality: dataToShow?.nationality || "",
    fathersName: dataToShow?.fathersName || "",
    fathersOccupation: dataToShow?.fathersOccupation || "",
    mothersName: dataToShow?.mothersName || "",
    mothersOccupation: dataToShow?.mothersOccupation || "",
    medium: dataToShow?.medium || "",
    nameOfSchool: dataToShow?.nameOfSchool || "",
    addmissionSeekingForClass: dataToShow?.addmissionSeekingForClass || "",
    address: dataToShow?.address || "",
    place: dataToShow?.place || "",
    date: dataToShow?.date || null,
    mobileNumber: dataToShow?.mobileNumber || "",
    whatsappNumber: dataToShow?.whatsappNumber || "",
    // studentPhoto: dataToShow.studentPhoto || "",
    // parentSignature: dataToShow.parentSignature || ""
  });
  const handleAddForm = (e) => {
    const { name, type, checked, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleReset = () => {
    setAddForm({
      school: "",
      // local: false,
      // hostel: false,
      residency: "",
      gender: "",
      board: "",
      // male: false,
      // female: false,
      nameOfPupil: "",
      dateOfBirth: "",
      motherTongue: "",
      nationality: "",
      fathersName: "",
      fathersOccupation: "",
      mothersName: "",
      mothersOccupation: "",
      medium: "",
      nameOfSchool: "",
      addmissionSeekingForClass: "",
      address: "",
      place: "",
      date: "",
      mobileNumber: "",
      whatsappNumber: "",
      studentPhoto: null,
      parentSignature: null,
      class: "",
    });

    setPreviewUrl(null);
    setSignaturePreview(null);
  };

  const handlePhoto = async (e, type) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const previewUrl = URL.createObjectURL(e.target.files[0]);
      if (type === "photo") {
        setPreviewUrl(previewUrl);
      } else {
        setSignaturePreview(previewUrl);
      }

      const res = await post(urls.fileUpload.publicFileUpload, formData);

      setAddForm((prev) => ({
        ...prev,
        [type === "photo" ? "studentPhoto" : "parentSignature"]:
          res?.data?.result[0]?.link,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...addForm,
      };

      const { data } = await post(urls.vaccationForm.create, payload);
      setFormSubmitted(true);
      handleReset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {dataToShow ? (
        <>
          {" "}
          <Box
            ref={componentRef}
            sx={{
              m: 1,
              zoom: 0.7,
              "@media print": {
                zoom: 0.78,
              },
            }}
          >
            <Box sx={{ border: "1px double black", p: 2 }}>
              {" "}
              <Typography variant="h5" align="center" fontWeight="bold">
                Excellent Vacation Classes
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                fontWeight={"bold"}
              >
                Excellent English Medium Primary and High School, Vijayapur
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                fontWeight={"bold"}
              >
                Web:{" "}
                <a target="_blank" rel="noopener noreferrer">
                  www.excellentschool.in
                </a>{" "}
                | Mail: <a>excellentschool2008@gmail.com</a> | Ph:{" "}
                <a>8884446691</a>
              </Typography>
              <Typography
                variant="h6"
                align="center"
                fontWeight="bold"
                sx={{ border: "1px solid black" }}
                my={2}
              >
                APPLICATION FORM - 2025
              </Typography>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup row name="school" value={addForm.school}>
                          <FormControlLabel
                            value="Excellent"
                            control={<Radio size="small" />}
                            label={
                              <Typography fontWeight="bold" variant="body2">
                                EXCELLENT SCHOOL
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            value="Other"
                            control={<Radio size="small" />}
                            label={
                              <Typography fontWeight="bold" variant="body2">
                                OTHER SCHOOL
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6} container alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          Class:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          variant="outlined"
                          name="class"
                          value={addForm.class}
                          size="small"
                          sx={{ width: "60px", textAlign: "center" }}
                          inputProps={{
                            style: { textAlign: "center", padding: "5px" },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="residency"
                          value={addForm.residency}
                        >
                          <FormControlLabel
                            value="Local"
                            control={<Radio size="small" />}
                            label={
                              <Typography fontWeight="bold" variant="body2">
                                Local
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="Hostel"
                            control={<Radio size="small" />}
                            label={
                              <Typography fontWeight="bold" variant="body2">
                                Hostel
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="body2">Syllabus / Board:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup row name="board" value={addForm.board}>
                          <FormControlLabel
                            value="State"
                            control={<Radio size="small" />}
                            label={
                              <Typography fontWeight="bold" variant="body2">
                                State
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="CBSE"
                            control={<Radio size="small" />}
                            label={
                              <Typography fontWeight="bold" variant="body2">
                                CBSE
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems={"center"}
                >
                  <Stack
                    spacing={2}
                    // sx={{ border: "1px solid black" }}
                    alignItems="center"
                    p={1}
                  >
                    {previewUrl && (
                      <img
                        src={previewUrl}
                        alt="Preview"
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                          borderRadius: "5px",
                          padding: "2px",
                          border: "1px solid #ccc",
                        }}
                      />
                    )}
                    {/* <Button variant="outlined" size="small" component="label">
                  Upload Student Photo
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => handlePhoto(e, "photo")}
                  />
                </Button> */}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={2}>
                      <ListItem>
                        1] Name of the pupil with surname as in school record in
                        full:
                      </ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="nameOfPupil"
                        value={addForm.nameOfPupil}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={2}>
                      <ListItem>2] Date of Birth:</ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="dateOfBirth"
                        value={
                          addForm.dateOfBirth
                            ? dayjs(addForm.dateOfBirth).format("YYYY-MM-DD")
                            : ""
                        }
                        type="date"
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4}>
                      <ListItem>3] Gender:</ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl component="fieldset">
                        <RadioGroup row name="gender" value={addForm.gender}>
                          <FormControlLabel
                            value="Male"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="body2">Male</Typography>
                            }
                          />
                          <FormControlLabel
                            value="Female"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="body2">Female</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={"-12px"}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={2}>
                      <ListItem>4] Mother Tongue:</ListItem>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="motherTongue"
                        value={addForm.motherTongue}
                        required
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={2.5}>
                          <ListItem sx={{ mt: 2 }}>Nationality:</ListItem>
                        </Grid>
                        <Grid item xs={9.5}>
                          <TextField
                            fullWidth
                            variant="standard"
                            name="nationality"
                            value={addForm.nationality}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={"-7px"}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={2}>
                      <ListItem>5] Father’s Name:</ListItem>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="fathersName"
                        value={addForm.fathersName}
                        required
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={2.5}>
                          <ListItem sx={{ mt: 2 }}>Occupation:</ListItem>
                        </Grid>
                        <Grid item xs={9.5}>
                          <TextField
                            fullWidth
                            variant="standard"
                            name="fathersOccupation"
                            value={addForm.fathersOccupation}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={"-7px"}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={2}>
                      <ListItem>6] Mother’s Name:</ListItem>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="mothersName"
                        value={addForm.mothersName}
                        required
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={2.5}>
                          <ListItem sx={{ mt: 2 }}>Occupation:</ListItem>
                        </Grid>
                        <Grid item xs={9.5}>
                          <TextField
                            fullWidth
                            variant="standard"
                            name="mothersOccupation"
                            value={addForm.mothersOccupation}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={1}>
                      <ListItem>7] Medium:</ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="medium"
                        value={addForm.medium}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={1}>
                      <ListItem>8] Name of the School:</ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="nameOfSchool"
                        value={addForm.nameOfSchool}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={1}>
                      <ListItem>9] Admission Seeking for the class:</ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="addmissionSeekingForClass"
                        value={addForm.addmissionSeekingForClass}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} mt={1}>
                      <ListItem>10] Address:</ListItem>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="standard"
                        name="address"
                        value={addForm.address}
                        multiline
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} md={4}></Grid>
                <Grid item xs={8} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={5}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <ListItem
                            sx={{
                              display: "flex",
                              alignItems: "center",

                              mt: 2,
                            }}
                          >
                            <Phone
                              sx={{ marginRight: 0.5, fontSize: "14px" }}
                            />{" "}
                            Mob:
                          </ListItem>
                        </Grid>
                        <Grid item xs ml={1}>
                          <TextField
                            fullWidth
                            type="number"
                            variant="standard"
                            name="mobileNumber"
                            value={addForm.mobileNumber}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={7}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <ListItem
                            sx={{
                              display: "flex",
                              alignItems: "center",

                              mt: 2,
                            }}
                          >
                            <WhatsApp
                              sx={{ marginRight: 0.5, fontSize: "14px" }}
                            />{" "}
                            WhatsApp:
                          </ListItem>
                        </Grid>
                        <Grid item xs ml={1}>
                          <TextField
                            fullWidth
                            type="number"
                            variant="standard"
                            name="whatsappNumber"
                            value={addForm.whatsappNumber}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container ml={1} spacing={1}>
                  <Grid item xs={12}>
                    <Typography sx={{ mt: 1.5, textAlign: "left" }}>
                      DECLARATION: I hereby declare that the above information
                      is true to the best of my knowledge. I will pay all
                      vacation fees and abide by the rules & regulations of the
                      institution.
                    </Typography>
                  </Grid>
                  <Grid item xs={8} mb={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={1} mt={2}>
                            <ListItem>Place:</ListItem>
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              fullWidth
                              variant="standard"
                              name="place"
                              value={addForm.place}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={1} mt={2}>
                            <ListItem sx={{ mt: 1 }}>Date:</ListItem>
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              fullWidth
                              variant="standard"
                              name="date"
                              value={
                                addForm.date
                                  ? dayjs(addForm.date).format("YYYY-MM-DD")
                                  : ""
                              }
                              type="date"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems={"center"}
                  >
                    <Stack spacing={1} alignItems="center" p={1}>
                      {signaturePreview && (
                        <img
                          src={signaturePreview}
                          style={{
                            width: "200px",
                            height: "100px",
                            objectFit: "contain",
                            borderRadius: "5px",
                            padding: "2px",
                            border: "1px solid #ccc",
                          }}
                          alt="Preview"
                        />
                      )}
                      {/* <Button variant="outlined" size="small" component="label">
                    Upload Parent Signature
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => handlePhoto(e, "signature")}
                    />
                  </Button> */}
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container spacing={1} ml={1}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: 2,
                        border: "1px solid black",
                        fontSize: "18px",
                      }}
                    >
                      For Office Use
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={1.8} mt={2}>
                        <ListItem>Name of the pupil:</ListItem>
                      </Grid>
                      <Grid item xs={10.2}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="name"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={6} container alignItems="center">
                        <Grid item>
                          <Typography variant="body2">Class:</Typography>
                        </Grid>
                        <Grid item sx={{ pl: 1 }}>
                          <TextField
                            variant="outlined"
                            name="class"
                            size="small"
                            sx={{ width: "100px", textAlign: "center" }}
                            inputProps={{
                              style: { textAlign: "center", padding: "5px" },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={2} mt={2}>
                            <ListItem>Bus Stop:</ListItem>
                          </Grid>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              variant="standard"
                              name="name"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={2} mt={2}>
                        <ListItem>Reference:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          variant="standard"
                          name="reference"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={2} mt={2}>
                        <ListItem>Phone No:</ListItem>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          variant="standard"
                          name="phoneNumber"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={5} mr={8}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <ListItem>Source of Information</ListItem>
                      </Grid>
                      <Grid item>
                        <ListItem>Signature</ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              gap: 2,
              mt: 1,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              size="small"
              color="error"
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                  }}
                  onClick={handlePrint}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Box>
        </>
      ) : (
        <>
          {formSubmitted ? (
            <Box
              sx={{
                height: "320px",
                width: "500px",
                background:
                  "linear-gradient(135deg,rgb(124, 62, 35) 30%, #f07b47 90%)",
                margin: "auto",
                mt: "100px",
                borderRadius: "12px",
                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                textAlign: "center",
                padding: "25px",
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                🎉 Success!
              </Typography>
              <Typography variant="body1" sx={{ opacity: 0.9, mt: 1 }}>
                Your form has been submitted successfully.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mt: 3,
                  px: 4,
                  fontWeight: "bold",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  color: "#f07b47",
                  "&:hover": { backgroundColor: "#f1f1f1" },
                }}
                onClick={() => (window.location.href = "/")}
              >
                Go to Home
              </Button>
            </Box>
          ) : (
            <Paper
              elevation={3}
              sx={{
                py: 2,
                px: 4,

                maxWidth: 800,
                margin: "auto",
              }}
            >
              <Box
                component={"form"}
                onSubmit={handleSubmit}
                sx={{ zoom: 0.9 }}
              >
                <Typography variant="h5" align="center" fontWeight="bold">
                  Excellent Vacation Classes
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="center"
                  fontWeight={"bold"}
                >
                  Excellent English Medium Primary and High School, Vijayapur
                </Typography>

                <Typography
                  variant="subtitle2"
                  align="center"
                  fontWeight={"bold"}
                >
                  Web:{" "}
                  <a
                    href="https://www.excellentschool.in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.excellentschool.in
                  </a>{" "}
                  | Mail:{" "}
                  <a href="mailto:excellentschool2008@gmail.com">
                    excellentschool2008@gmail.com
                  </a>{" "}
                  | Ph: <a>8884446691</a>
                </Typography>

                <Typography
                  variant="h6"
                  align="center"
                  fontWeight="bold"
                  sx={{ border: "1px solid black" }}
                  my={2}
                >
                  APPLICATION FORM - 2025
                </Typography>

                <Grid container>
                  <Grid item xs={8}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="school"
                            value={addForm.school}
                            onChange={handleAddForm}
                          >
                            <FormControlLabel
                              value="Excellent"
                              control={<Radio size="small" />}
                              label={
                                <Typography fontWeight="bold" variant="body2">
                                  EXCELLENT SCHOOL
                                </Typography>
                              }
                            />

                            <FormControlLabel
                              value="Other"
                              control={<Radio size="small" />}
                              label={
                                <Typography fontWeight="bold" variant="body2">
                                  OTHER SCHOOL
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid
                        item
                        xs={6}
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography variant="body2" fontWeight={"bold"}>
                            Class:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            name="class"
                            value={addForm.class}
                            onChange={handleAddForm}
                            size="small"
                            sx={{ width: "60px", textAlign: "center" }}
                            inputProps={{
                              style: { textAlign: "center", padding: "5px" },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="residency"
                            value={addForm.residency}
                            onChange={handleAddForm}
                          >
                            <FormControlLabel
                              value="Local"
                              control={<Radio size="small" />}
                              label={
                                <Typography fontWeight="bold" variant="body2">
                                  Local
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="Hostel"
                              control={<Radio size="small" />}
                              label={
                                <Typography fontWeight="bold" variant="body2">
                                  Hostel
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          Syllabus / Board:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="board"
                            value={addForm.board}
                            onChange={handleAddForm}
                          >
                            <FormControlLabel
                              value="State"
                              control={<Radio size="small" />}
                              label={
                                <Typography fontWeight="bold" variant="body2">
                                  State
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="CBSE"
                              control={<Radio size="small" />}
                              label={
                                <Typography fontWeight="bold" variant="body2">
                                  CBSE
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems={"center"}
                  >
                    <Stack
                      spacing={2}
                      sx={{ border: "1px solid black" }}
                      alignItems="center"
                      p={1}
                    >
                      {previewUrl && (
                        <img
                          src={previewUrl}
                          alt="Preview"
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "contain",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                          }}
                        />
                      )}
                      <Button variant="outlined" size="small" component="label">
                        Upload Student Photo
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => handlePhoto(e, "photo")}
                        />
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={2}>
                        <ListItem>
                          1] Name of the pupil with surname as in school record
                          in full:
                        </ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="nameOfPupil"
                          value={addForm.nameOfPupil}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={2}>
                        <ListItem>2] Date of Birth:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="dateOfBirth"
                          value={addForm.dateOfBirth}
                          onChange={handleAddForm}
                          type="date"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={4}>
                <ListItem>3] Gender:</ListItem>
              </Grid>
              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="male"
                      size="small"
                      checked={addForm.male}
                      onChange={handleAddForm}
                    />
                  }
                  label={<Typography variant="body2">Male</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="female"
                      size="small"
                      checked={addForm.female}
                      onChange={handleAddForm}
                    />
                  }
                  label={<Typography variant="body2">Female</Typography>}
                />
              </Grid>
            </Grid>
          </Grid> */}

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4}>
                        <ListItem>3] Gender:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="gender"
                            value={addForm.gender}
                            onChange={handleAddForm}
                          >
                            <FormControlLabel
                              value="Male"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">Male</Typography>
                              }
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">Female</Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={"-12px"}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={2}>
                        <ListItem>4] Mother Tongue:</ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="motherTongue"
                          value={addForm.motherTongue}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={3}>
                            <ListItem sx={{ mt: 2 }}>Nationality:</ListItem>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              variant="standard"
                              name="nationality"
                              value={addForm.nationality}
                              onChange={handleAddForm}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={"-7px"}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={2}>
                        <ListItem>5] Father’s Name:</ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="fathersName"
                          value={addForm.fathersName}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={3}>
                            <ListItem sx={{ mt: 2 }}>Occupation:</ListItem>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              variant="standard"
                              name="fathersOccupation"
                              value={addForm.fathersOccupation}
                              onChange={handleAddForm}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={"-7px"}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={2}>
                        <ListItem>6] Mother’s Name:</ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="mothersName"
                          value={addForm.mothersName}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={3}>
                            <ListItem sx={{ mt: 2 }}>Occupation:</ListItem>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              variant="standard"
                              name="mothersOccupation"
                              value={addForm.mothersOccupation}
                              onChange={handleAddForm}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={1}>
                        <ListItem>7] Medium:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="medium"
                          value={addForm.medium}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={1}>
                        <ListItem>8] Name of the School:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="nameOfSchool"
                          value={addForm.nameOfSchool}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={1}>
                        <ListItem>9] Admission Seeking for the class:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="addmissionSeekingForClass"
                          value={addForm.addmissionSeekingForClass}
                          onChange={handleAddForm}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={4} mt={1}>
                        <ListItem>10] Address:</ListItem>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant="standard"
                          name="address"
                          value={addForm.address}
                          onChange={handleAddForm}
                          multiline
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={5}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <ListItem
                              sx={{
                                display: "flex",
                                alignItems: "center",

                                mt: 2,
                              }}
                            >
                              <Phone
                                sx={{ marginRight: 0.5, fontSize: "14px" }}
                              />{" "}
                              Mob:
                            </ListItem>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              fullWidth
                              type="number"
                              variant="standard"
                              name="mobileNumber"
                              value={addForm.mobileNumber}
                              onChange={handleAddForm}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <ListItem
                              sx={{
                                display: "flex",
                                alignItems: "center",

                                mt: 2,
                              }}
                            >
                              <WhatsApp
                                sx={{ marginRight: 0.5, fontSize: "14px" }}
                              />{" "}
                              WhatsApp:
                            </ListItem>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              fullWidth
                              type="number"
                              variant="standard"
                              name="whatsappNumber"
                              value={addForm.whatsappNumber}
                              onChange={handleAddForm}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography sx={{ mt: 1.5 }}>
                        DECLARATION: I hereby declare that the above information
                        is true to the best of my knowledge. I will pay all
                        vacation fees and abide by the rules & regulations of
                        the institution.
                      </Typography>
                    </Grid>
                    <Grid item xs={8} mb={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} mb={2}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1} mt={2}>
                              <ListItem>Place:</ListItem>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                variant="standard"
                                name="place"
                                value={addForm.place}
                                onChange={handleAddForm}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1} mt={2}>
                              <ListItem sx={{ mt: 1 }}>Date:</ListItem>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                variant="standard"
                                name="date"
                                value={addForm.date}
                                onChange={handleAddForm}
                                type="date"
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems={"center"}
                    >
                      <Stack
                        spacing={1}
                        sx={{ border: "1px solid black" }}
                        alignItems="center"
                        p={1}
                      >
                        {signaturePreview && (
                          <img
                            src={signaturePreview}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                            alt="Preview"
                          />
                        )}
                        <Button
                          variant="outlined"
                          size="small"
                          component="label"
                        >
                          Upload Parent Signature
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => handlePhoto(e, "signature")}
                          />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    size="small"
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" size="small" variant="contained">
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          )}
        </>
      )}
    </>
  );
};

export default VacationAdmissionForm;
