import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Card,
  Grid,
  TextareaAutosize,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useContext } from "react";
import SettingContext from "../../../context/SettingsContext";
import LoadingButton from "@mui/lab/LoadingButton";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TimezoneSelect from "react-timezone-select";
import moment from "moment-timezone";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    color: "#fff",
  },
};

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const timeZones = moment.tz.names();
  const [settingDetails, setSettingDetails] = useState({
    regDate: dayjs(Date.now()),
    sessionStartMonth: dayjs(Date.now()),
    sessionEndMonth: dayjs(Date.now()),
  });
  const [isSettingExists, setIsSettingExists] = useState(false);

  const [schoolLogoLink, setSchoolLogoLink] = useState("");
  const { setSettingsContext } = useContext(SettingContext);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSettingDetails({ ...settingDetails, [name]: value });
  };

  const handlePhoto = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
      const res = await post(urls.fileUpload.postFileUpload, formData);
      setSettingDetails({ ...settingDetails, logo: res.data.result[0]._id });
      setSchoolLogoLink(res.data.result[0].link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const data = {
        ...settingDetails,
        schoolName: settingDetails.schoolName,
        address: settingDetails.address,
        phone: settingDetails.phone,
        regDate: dayjs(settingDetails.regDate).format("YYYY-MM-DD"),
        email: settingDetails.email,
        schFax: settingDetails.schFax,
        websiteFooter: settingDetails.websiteFooter,
        description: settingDetails.description,
        logo: settingDetails.logo,
        currency: settingDetails.currency,
        currencySymbol: settingDetails.currencySymbol,
        sessionStartMonth: dayjs(settingDetails.regDate).format("YYYY-MM-DD"),
        sessionEndMonth: dayjs(settingDetails.regDate).format("YYYY-MM-DD"),
        // sessionStartMonth: settingDetails.sessionStartMonth,
        // sessionEndMonth: settingDetails.sessionEndMonth,
        rollNumberType: settingDetails.rollNumberType,
        admissionNo: settingDetails.admissionNo,
        latitude: settingDetails.latitude,
        longitude: settingDetails.longitude,
        googleAnalyticsId: settingDetails.googleAnalyticsId,
        defaultTimeZone: settingDetails.defaultTimeZone,
      };
      if (isSettingExists) {
        const res = await put(
          urls.settings.putSettings,
          settingDetails._id,
          data
        );
        setSettingsContext((prev) => ({
          ...prev,
          schoolName: data.schoolName,
          schoolLogo: schoolLogoLink,
        }));
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        const res = await post(urls.settings.postSettings, data);
        setSettingsContext((prev) => ({
          ...prev,
          schoolName: data.schoolName,
          schoolLogo: schoolLogoLink,
        }));
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      console.log(error);
    }
  };

  const handleChangeTimeZone = (t) => {
    setSettingDetails({ ...settingDetails, defaultTimeZone: t });
  };

  const getTimeZoneOffset = (zone) => {
    const offset = moment.tz(zone).format("Z"); // Get the offset in ±HH:mm format
    const hours = offset.slice(0, 3); // Extract the hours part
    const minutes = offset.slice(3); // Extract the minutes part
    const country = zone.split("/")[0]; // Get the country name from the time zone

    return `${country} (GMT${hours}:${minutes})`;
  };
  useEffect(() => {
    const getSettings = async () => {
      try {
        const response = await get(urls.settings.getAllSettings);

        if (response.data.data.length > 0) {
          setIsSettingExists(true);
          setSettingDetails(response.data.data[0]);

          if (response.data.data[0].logo) {
            setPreviewUrl(response.data.data[0].logo.link);
            setSchoolLogoLink(response.data.data[0].logo.link);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSettings();
  }, []);

  console.log(settingDetails, "sd");

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: 15 }}>
          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={12} mg={12} lg={12}>
                <span
                  style={{
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  Settings :
                </span>
              </Grid>
            </Grid>
          </Card>

          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <h5 style={{ color: "#1b3779" }}>Basic Information:</h5>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    variant="standard"
                    name="schoolName"
                    value={settingDetails.schoolName}
                    onChange={(event) => handleOnChange(event)}
                    label="School Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    variant="standard"
                    label="Address"
                    name="address"
                    value={settingDetails.address}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    variant="standard"
                    label="Phone"
                    name="phone"
                    value={settingDetails.phone}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Registration Date"
                    name="regDate"
                    value={dayjs(settingDetails.regDate).format("YYYY-MM-DD")}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    variant="standard"
                    label="Email "
                    name="email"
                    value={settingDetails.email}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-required"
                    variant="standard"
                    label="Fax"
                    name="schFax"
                    value={settingDetails.schFax}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    id="filled-required"
                    variant="standard"
                    label="Website Footer"
                    name="websiteFooter"
                    value={settingDetails.websiteFooter}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextareaAutosize
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    name="description"
                    value={settingDetails.description}
                    onChange={(event) => handleOnChange(event)}
                    style={{
                      width: "100%",
                      marginLeft: 10,
                      height: 100,
                      padding: "10px",
                    }}
                    aria-label="maximum height"
                    placeholder="Drop a note"
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>

          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <h5 style={{ color: "#1b3779" }}>Setting Information:</h5>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" required fullWidth>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      label="Currency"
                      id="demo-simple-select-filled"
                      value={"rupee"}
                      onChange={(event) => handleOnChange(event)}
                      name="currency"
                      fontSize="small"
                    >
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"rupee"}
                      >
                        Indian Rupee
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" required fullWidth>
                    <InputLabel>Currency Symbol</InputLabel>
                    <Select
                      key={settingDetails._id}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      value={settingDetails.currencySymbol}
                      onChange={(event) => handleOnChange(event)}
                      name="currencySymbol"
                      label="Currency Symbol"
                    >
                      <MenuItem value={"rupeeIcon"}>
                        <CurrencyRupeeIcon
                          sx={{ fontSize: 14, fontWeight: 500 }}
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Session Start month"
                    name="sessionStartMonth"
                    value={dayjs(settingDetails.sessionStartMonth).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Session End Month"
                    name="sessionEndMonth"
                    value={dayjs(settingDetails.sessionEndMonth).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" required fullWidth>
                    <InputLabel>Enable Fronted</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Currency Symbol">
                      <MenuItem value={"yes"}>Yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>roll Number Type</InputLabel>
                    <Select
                      key={settingDetails.rollNumberType}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="roll Number Type"
                      name="rollNumberType"
                      value={settingDetails.rollNumberType}
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem value={"manual"}>manual</MenuItem>
                      <MenuItem value={"ascendingName"}>
                        auto ascending name
                      </MenuItem>
                      <MenuItem value={"ascendingNameFemale"}>
                        auto ascending name Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Admission Number</InputLabel>
                    <Select
                      key={settingDetails.admissionNo}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Admission No"
                      name="admissionNo"
                      value={settingDetails.admissionNo}
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem value={"manual"}>manual</MenuItem>
                      <MenuItem value={"autoAscendingNo"}>
                        autoAscendingNo
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-required"
                    fullWidth
                    label="Latitude"
                    name="latitude"
                    value={settingDetails.latitude}
                    onChange={(event) => handleOnChange(event)}
                    variant="standard"
                    sx={{ mt: 0.7, label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="standard"
                    label="Logitude"
                    sx={{ mt: 0.7, label: { fontSize: 12 } }}
                    name="longitude"
                    value={settingDetails.longitude}
                    onChange={(event) => handleOnChange(event)}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Default Time Zone</InputLabel>
                    <Select
                      label="Default Time Zone"
                      sx={styles.textfield}
                      id="demo-simple-select-filled"
                      name="timeZone"
                      size="small">
                      {timeZones.map((zone, index) => (
                        <MenuItem key={index} value={zone}>
                          {getTimeZoneOffset(zone)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} md={6} lg={3}>
                  <TimezoneSelect
                    key={settingDetails.defaultTimeZone}
                    value={settingDetails.defaultTimeZone || {}}
                    onChange={handleChangeTimeZone}
                    name="defaultTimeZone"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    fullWidth
                    value={settingDetails.googleAnalyticsId}
                    onChange={(event) => handleOnChange(event)}
                    label="Google Analytics"
                    sx={{ label: { fontSize: 12 } }}
                    name="googleAnalyticsId"
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>

          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <h5 style={{ color: "#1b3779" }}>Social Information:</h5>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="facebookUrl"
                    value={settingDetails.facebookUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Facebook URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="twitterUrl"
                    value={settingDetails.twitterUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Twitter URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="linkedinUrl"
                    value={settingDetails.linkedinUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Linkedin URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="gplusUrl"
                    value={settingDetails.gplusUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Google Plus URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="youtubeUrl"
                    value={settingDetails.youtubeUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Youtube URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="instagramUrl"
                    value={settingDetails.instagramUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Instagram URL"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="pinterestUrl"
                    value={settingDetails.pinterestUrl || ""}
                    onChange={handleOnChange}
                    id="filled-required"
                    variant="standard"
                    label="Pinterest URL "
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <div>
            <Card style={styles.card}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h5 style={{ color: "#1b3779" }}>Logo Upload:</h5>
                  </Grid>
                  <form>
                    <Grid item lg={12} sx={{ marginLeft: "20px" }}>
                      <Button
                        variant="Contained"
                        type="submit"
                        sx={styles.Button}
                        component="label"
                      >
                        Upload
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          value={schoolLogo}
                          onChange={handlePhoto}
                        />
                      </Button>

                      {previewUrl && (
                        <img
                          src={previewUrl}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                            marginLeft: " 40px",
                          }}
                          alt="Preview"
                        />
                      )}
                    </Grid>
                  </form>
                </Grid>
              </Box>
            </Card>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                type="submit"
                // style={{ backgroundColor: "#1b3779", color: "#ffff" }}
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: "#1b3779",
                  ":hover": { backgroundColor: "#1b3779" },
                }}
              >
                {isSettingExists ? "Update" : "Create"}
              </LoadingButton>
            </Grid>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Settings;
