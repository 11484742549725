import React, { useState, useEffect } from "react";

import Footer from "./Footer/Footer";
import { ContactEmergency } from "@mui/icons-material";
import { Container, Divider, Typography, styled } from "@mui/material";
import css from "./Addmission.module.css";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import { Link, NavLink } from "react-router-dom";

const guideLines = [
  "Each candidate seeking admission must be introduced in person by the individual responsible for the child to the principal.",
  "Admissions are subject to seat availability. ",
  "For admission to Grade I, students must have completed six years of age by September 30 of the academic year. An original Birth Certificate must be submitted at the time of admission to verify the declared date of birth.",
  "Students transferring from recognized schools must provide the original Leaving Certificate from their previous school.",
  "If transferring from a recognized school outside the state, the Leaving Certificate must be counter-signed by the inspecting authority of that state (Transfer Certificate).",
  "Admissions will be processed only through proper channels. Special references or recommendations will not be entertained or considered.",
  "The management of Excellent School retains the sole discretion to admit or retain students in the institution.",
];

const pageContent = [
  {
    title: "Fees",
    subContennt: [
      "Annual fees must be paid in full or in installments, as per the schedule set by Excellent School, on or before the designated dates.",
      "For installment payments, post-dated cheques are mandatory for all installments for the academic year's fees. Cash payments for post-dated cheques can be deposited one week before the scheduled cheque date",
      "Once paid, fees are non-transferable, non-adjustable, and non-refundable under any circumstances.",
      "Parents are expected to make separate payments for outstation tours, entry fees for various competitions, and fees for public, competitive, or board examinations, as applicable. Additionally, parents will need to cover expenses for textbooks, notebooks, school uniforms, sports uniforms, sports accessories, canteen expenses, and other related costs.",
      "A fee hike of 10% to 12% is expected annually for each grade until Grade VII.",
      "For Playgroup to Grade I, fees will be charged based on the prevailing fee structure at the time.",
      "Transport fees (if opted) must be paid term-wise. Service tax, as applicable, will also be charged.",
      "The allotment of shifts and divisions is solely at the discretion of the school’s Administrative Department.",
      "Under normal conditions, changes in batch/division are not permitted. However, in special cases, such changes may be allowed at the discretion of the Administrative Department, with a transfer fee of Rs. 2500/- applied.",
      "A Bonafide Certificate will be issued one week after the application, with a charge of Rs. 150/-.",
      "Duplicate I-cards (for students and parents) or extra I-cards will be provided at a charge of Rs. 200/- after 15 days from receipt of payment.",
      "Transport and sports fees are payable separately, as applicable, at the time of admission or invoice generation.",
      "If admission is cancelled midterm, No Dues Certificate and Leaving Certificate will only be issued after the fees for the entire academic year are cleared.",
      "Service tax and other statutory taxes, where applicable, will be charged separately.",
      "The school management reserves the right to modify fees as necessary.",
    ],
  },
  {
    title: "Complimentary Student Kit",
    subContennt: [
      "A Student Kit will be provided free of charge to all students on the day of Parent Orientation, which will take place one or two weeks prior to the start of the academic year. The kit is given to ensure uniformity among students and to discourage the use of fancy or expensive items at school.",
      "Parents are required to inspect all items in the kit before accepting it. Once the kit is handed over to the parents, Excellent School will not be held responsible for the quality of the items and will be absolved of any concerns regarding wear and tear. The school will not entertain complaints related to the selection, color, size, design, shape, feel, quality, or quantity of the items in the kit. Additionally, the school will not consider any requests for replacement or exchange of the items once the kit has been delivered to the parents.",
    ],
  },
  {
    title: "School Uniform & Appearance",
    subContennt: [
      "Students are expected to dress and behave in a manner that reflects the values of Excellent School. They must adhere to the prescribed uniform regulations throughout the school day and during any special occasions as specified by the school.",
      "A neat and tidy uniform, as recommended by the school, along with an identity card, must be worn daily. Students without the proper uniform will not be allowed entry.",
      "Socks and shoes should conform to the style approved by the school. A wristwatch with a black strap and black hair accessories, as recommended by the school, must also be worn.",
      "Students are required to maintain cleanliness and neatness in their appearance. Girls should keep their hair plaited or tied neatly, while boys should have their hair trimmed. Hair should not be colored, tinted, or highlighted. The school promotes simplicity, so students are not allowed to wear flashy or expensive items such as jewelry, fancy watches, wristbands, or similar accessories. Only plain black hairbands, ribbons, rubber bands, and belts are permitted.",
      "Sweaters and cardigans must adhere to the style recommended by the school. No other colors or fancy designs will be allowed on the school premises.",
      "Fingernails must be kept short and clean.",
    ],
  },
  {
    title: "Ban on Use of Mobile Phones",
    subContennt: [
      "Mobile phones and other portable devices such as iPads, iPods, Tablets, etc., are strictly prohibited from being used or even seen on the campus at any time. Excellent School will not be responsible for any incidents involving theft, loss, or misuse of these devices. If such devices are found, they will be confiscated by a school staff member and will only be returned to the parents by the Principal, who will require them to come to the school.",
      "If you need to contact your child during school hours for an emergency, please call the school reception, which will relay the message to the student. The ban on mobile phones and devices also applies during break times and while traveling on the school bus.",
      "Excellent School will take strict action against any form of cybercrime, including defamation of the school or its staff on social media, whether the posts are made during or outside school hours.",
    ],
  },
  {
    title: "Birthday Celebrations",
    subContennt: [
      "Excellent School acknowledges that birthdays are special occasions for both students and parents. However, the school requests that birthday celebrations be kept simple. The distribution of candies, toffees, chocolates, or any take-home gifts for students or teachers is not permitted. Parents may send half or one kilogram of eggless cakes, without cream.",
      "Parents are not allowed to participate in classroom celebrations. Birthday cakes can be ordered from the school canteen by placing an order in advance.",
      "On their birthday, students may wear clothes of their choice, provided they adhere to norms of modesty. Photography and video recording of the celebration are strictly prohibited. ",
    ],
  },
  {
    title: "General Rules",
    subContennt: [
      "There is one fundamental rule at Excellent School: all students are expected to behave well, sensibly, and show courtesy, consideration, and respect for others, ensuring that nothing they do brings the school's reputation into disrepute.",
      "The school reserves the right to make changes to the rules, which will be communicated to students and parents, and these changes will be binding.",
      "School rules apply to all students from the moment they leave home for school until they return home at the end of the school day, as well as during any school-related activities.",
      "While the school is not responsible for student behavior outside its premises, any reports of misconduct will be investigated, and disciplinary action will be taken if the report is substantiated.",
      "Irregular attendance, physical violence on school premises or school buses, misbehavior with fellow students, habitual laziness, disobedience, dishonesty, or any behavior that harms the moral tone of the school may lead to dismissal. The Principal’s decision in such cases is final.",
      "Any damage to school property must be compensated. Students must take care of any equipment lent to them for use.",
      "The school is not responsible for the loss, damage, or theft of students' belongings. Students are advised not to bring money or valuable items unnecessarily to school.",
      "Students are expected to carry only the books required for the day's timetable. No books (other than textbooks or school library books), newspapers, or periodicals may be brought to school without the Principal’s permission.",
      "No student is exempt from Games and Physical Training without a medical certificate, which must specify the exemption period.",
      "Students are strictly forbidden from purchasing items from street hawkers. Homemade vegetarian food should be brought in tiffins, and the canteen may be used when necessary. Non-vegetarian food is not allowed on school premises.",
      "Spoken English is encouraged within the school and on school premises.",
      "All students are required to speak only in English once they are in the school uniform.",
      "Students are not allowed to give gifts or make demonstrations to teachers or staff members, whether on occasions like birthdays, Children’s Day, Teacher’s Day, Diwali, etc.",
    ],
  },
  {
    title: "Extra Curricular Activities",
    subContennt: [
      "Excellent School organizes extracurricular activities for students at an additional cost. However, the school is not responsible for any dealings between the coaches, instructors, or trainers and will not guarantee any refunds or results as stated by the course conductors.",
      "Parents are not permitted to enter the school premises while the courses or classes are in session. Additionally, parents will not be allowed to sit and wait for their children within the school premises.",
      "The school does not provide transport facilities for any extracurricular activities it organizes.",
    ],
  },
  {
    title: "Parent Interaction System",
    subContennt: [
      "Excellent School is committed to providing quality services to both its students and parents. Our official website, www.excellentschool.in, offers easy access to all that our institution represents, including our philosophy, vision, administrative procedures, services, almanac, and more. The website features user-friendly links that allow both parents and students to stay updated on the latest happenings at the school. Important announcements can be quickly reviewed, and our e-campus software provides students and parents with access to daily homework, classwork, test schedules, class timetables, results, mark sheets, sample question papers, library books, and various other online resources. This platform, which is updated daily, also allows you to update communication details, such as changing phone numbers or addresses. ",
      "We are excited to introduce the Mcampus (Mobile Application), a first-of-its-kind mobile app for Excellent School students and parents. This app will enable parents to access their child’s classwork, homework, attendance, class test marks, information slips, circulars, class and exam timetables, and daily updates in real-time, directly on their mobiles.",
      "You will receive your username, password, and instructions for downloading the mobile app individually.",
    ],
  },
  {
    title: "General Issues",
    subContennt: [
      "Please ensure that your child's name is written (with a marker) on all items used by them at school (such as the bag, cap, shoes, tiffin box, etc.).",
      "Laminate the parent I-card or use the plastic pouches provided by the school.",
      "Make it a habit to check the school bag and diary daily, as they contain important information.",
      "The school uniform has been designed with children's preferences in mind. Ensure that it is kept clean and hygienic.",
      "Please paste a photo of the person authorized to pick up your child on the parent I-card (an extra parent I-card can be requested). If your child is expected to return home on their own (only for Grade II and above), an undertaking must be submitted.",
      "If someone else will be picking up your child, they must have both the I-card and a letter of authority.",
      "A child will not be handed over to anyone who is not recognized by the teacher or security.",
      "We request that you arrive on time to pick up your child.",
      "Please avoid discussing your child's progress with the class teacher during pick-up or drop-off times.",
      "At the end of the school day, buses will leave first, followed by the walkers once the buses have departed.",
      "Parents are requested to refrain from waiting on school premises during school hours, unless meeting with the Principal or teachers by prior appointment.",
      "Fancy tiffin boxes are not permitted. Please use only the tiffin boxes provided by the school.",
      "Tiffin boxes should not be oversized. Avoid packing wafers, sweets, or similar items. Friday is Fruit Day, so please pack fresh fruits in your child's tiffin.",
      "Eggs and non-vegetarian food are strictly prohibited on school premises.",
    ],
  },
  {
    title: "Withdrawal",
    subContennt: [
      "A minimum of one calendar month’s notice must be provided before the withdrawal of a student. This notice must be given in writing by the responsible person, not the student. Please note that fees once paid, including admission fees, are non-refundable and cannot be transferred under any circumstances.",
      "Students who leave without proper notice, or whose names are removed from the rolls due to unexplained absence of more than one month, are generally not re-admitted.",
      "Parents are requested to inform the school office promptly when a student leaves. Additionally, fees must be paid for the entire academic year.",
      "The Leaving Certificate, which is required for admission to another school, will not be issued to students who have not cleared all fees and dues.",
      "A Leaving Certificate request must be made in writing by the parent or guardian, using the prescribed form, along with No Due Certificates from the Accounts and Library Departments. A month’s notice is required before a student’s departure from the school.",
      "Students who wish to discontinue their studies should obtain their Leaving Certificates immediately. A fee will be charged if the certificate is requested after one year, and the prescribed procedure must be followed for issuing a duplicate. ",
    ],
  },
];

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 600,
  padding: "15px 0px",
  fontFamily: "Merriweather, serif",
}));

const Content = styled(Typography)(({ theme }) => ({
  fontFamily: "Source Sans Pro, serif",
  fontSize: "16px",
}));

const TermsAndCondition = () => {
  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>TERMS & CONDITION</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / TERMS & CONDITION
          </h5>
        </div>
      </div>
      <Container maxWidth="xl">
        <SubTitle mt={2} fontWeight={"bold"}>
          ADMISSION GUIDELINES
        </SubTitle>
        <Typography component={"ol"}>
          {guideLines.map((row, index) => (
            <Content sx={{ padding: "8px 0px" }} component={"li"} key={index}>
              {row}
            </Content>
          ))}
        </Typography>
        <SubTitle>CONVERSION FORM</SubTitle>
        <Content>
          All students from Playgroup to Grade 1 must complete a conversion form
          and generate an invoice for the upcoming academic year as per the
          schedule provided annually by Excellent School. This process ensures
          automatic promotion to the next class, subject to the student meeting
          academic performance, attendance, and the general rules and
          regulations of the school.{" "}
        </Content>
        <br />
        <Content>
          If the conversion form and invoice are not completed by the specified
          deadline, it will be assumed that the parent does not wish to seek
          admission for the next academic year. In such cases, the seat will be
          allocated to new applicants.
        </Content>
        <br />
        <Content>
          No requests for conversion will be accepted after the deadline.
        </Content>

        {pageContent.map((row, index) => (
          <>
            <SubTitle key={index}>{row.title.toUpperCase()} </SubTitle>
            {/* {row.subContennt.map((innerRow, innerIndex) => (
              <Content key={innerIndex} paddingBottom={2}>
                {innerRow}
              </Content>
            ))} */}

            {row.subContennt.map((innerRow, innerIndex) => (
              <Content key={innerIndex} paddingBottom={2}>
                {innerRow
                  .split("www.excellentschool.in")
                  .map((part, partIndex, array) => (
                    <React.Fragment key={partIndex}>
                      {part}
                      {partIndex < array.length - 1 && (
                        <a
                          href="https://www.excellentschool.in"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "purple", textDecoration: "none" }}
                        >
                          www.excellentschool.in
                        </a>
                      )}
                    </React.Fragment>
                  ))}
              </Content>
            ))}
          </>
        ))}
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndCondition;
